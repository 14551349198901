// JavaScript Document


$( document ).ready(function() {
    //$("#damsel1").fadeIn(6000);
	// $('div.dungeon1-image-back').fadeIn(5000);
	AOS.init({
  duration: 1200,
  easing: 'ease-in-out-back'
});
	
	$(".newsletter__wrapper").removeClass();
	$(".newsletter-form__message").removeClass().addClass("taglinecoral");
/*setTimeout(
	  function() 
	  {
        $("#empty1").hide();
		$("#damsel1").fadeIn(3000);
	  }, 3000);
	*/
	
	
});	



